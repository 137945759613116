import React from "react";
import logo from "../assets/logo.svg";
import logoColor from "../assets/logo-color.svg";
import Image from "next/image";
import Link from "next/link";
import Navigation from "./Navigation";

const Header = ({ headerLinks, overlayHeader }) => {
  return (
    <header className="relative dark:bg-gray-900">
      <div className={`flex justify-between items-center py-4 container ${overlayHeader ? `absolute inset-x-0 z-20` : ``}`}>
        <div>
          <Link href="/">
            <Image
              src={logoColor}
              alt="Arjen Miedema - Ecommerce Developer"
              className="block dark:hidden"
            />
            <Image
              src={logo}
              alt="Arjen Miedema - Ecommerce Developer"
              className="hidden dark:block"
            />
          </Link>
        </div>
        <Navigation headerLinks={headerLinks} overlayHeader={overlayHeader} />
      </div>
    </header>
  );
}

export default Header;
