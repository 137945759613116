import { SliceZone } from '@prismicio/react'

import { createClient } from '../prismicio'
import { components } from '../slices'
import Head from "next/head";
import { Layout } from "../components";

const HomePage = ({ page, headerLinks, footer }) => (
  <>
    <Head>
      <title>
        {`${page.data.meta_title || page.data.title[0].text} - Arjen Miedema`}
      </title>
      <link rel="icon" href="/favicon.ico"/>
      {page.data.meta_description && <meta name="description" content={page.data.meta_description}/>}
    </Head>
    <Layout headerLinks={headerLinks} page={page} footer={footer}>
      <SliceZone slices={page.data.slices || page.data.body} components={components} context={{page}}/>
    </Layout>
  </>
)

export default HomePage

export async function getStaticProps({ previewData }) {
  const client = createClient({ previewData });

  const page = await client.getSingle('homepage');

  const headerNavigation = await client.getSingle('header_navigation'),
    footer = await client.getSingle('footer_navigation');

  return {
    props: {
      page,
      headerLinks: headerNavigation?.data?.menu_items || [],
      footer
    },
  }
}
