import React, { useState } from "react";
import { PrismicLink } from "@prismicio/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";

const Navigation = ({ headerLinks, overlayHeader }) => {
  const [open, setOpen] = useState(false);

  return (
    <nav className="flex md:justify-between">
      <div className="flex md:hidden">
        <button
          className="flex flex-col gap-1 items-center relative w-10"
          title="Open Navigation"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon icon={faBars} className="fill-current text-blue-darker dark:text-gray-100 w-8 h-8"/>
        </button>
      </div>
      <div className={`fixed md:static inset-0 z-30 block md:visible backdrop-blur-sm md:backdrop-blur-0 ${open ? 'visible' : 'invisible'}`}>
        <div
          className="bg-black absolute md:hidden z-10 bg-opacity-30 w-full inset-0"
          onClick={() => setOpen(false)}
        >
        </div>
        <div className={`bg-white dark:bg-gray-700 dark:md:bg-transparent md:bg-transparent absolute md:static z-20 right-0 h-full w-11/12 ml-auto md:ml-0 md:w-auto translation-all md:translate-x-0 duration-300 ${open ? 'translate-x-0' : 'translate-x-full'}`}>
          <div className="flex justify-end mt-4 mr-4 md:hidden">
            <button className="flex flex-col items-center relative w-10"
                    title="Close Navigation"
                    onClick={() => setOpen(false)}>
              <FontAwesomeIcon icon={faClose} className="fill-current text-blue-darker dark:text-gray-100 w-8 h-8"/>
            </button>
          </div>
          <ul className="flex-col z-10 my-4 text-lg md:my-0 flex md:flex-row md:justify-center md:gap-8">
            {headerLinks?.map((item) =>
              <li key={item.link.id}>
                <PrismicLink
                  field={item.link}
                  onClick={() => setOpen(false)}
                  className={`block p-3 md:p-0 md:inline text-gray-600 dark:text-white lowercase hover:text-primary-lighter hover:underline border-b border-gray-200 dark:border-gray-500 md:border-0 ${overlayHeader ? `md:text-white` : ``}`}
                >
                  {item.label}
                </PrismicLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
