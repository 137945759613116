import React from "react";
import { PrismicLink } from "@prismicio/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";

const { library } = require('@fortawesome/fontawesome-svg-core');
library.add(fab);

const Footer = ({ footer }) => (
  <footer className="bg-blue-light dark:bg-gray-900 dark:text-white py-10 text-sm">
    <div className="container">
      <div className="flex flex-col gap-4 lg:flex-row justify-center lg:justify-between">
        <div className="flex flex-col lg:flex-row gap-4 items-center">
          &copy; Copyright {new Date().getFullYear()} - Arjen Miedema
          <span className="hidden lg:block">|</span>
          {footer?.data?.links && (
            <div className="flex gap-4 items-center">
              {footer.data.links.map((item, index) => (
                <div key={index}>
                  <PrismicLink field={item.link} className="hover:underline hover:text-orange">
                    {item.label}
                  </PrismicLink>
                  {index + 1 < footer.data.links.length ? <span className="ml-4">|</span> : ''}
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          <div className="flex gap-4 items-center justify-center">
            {footer?.data?.socials.map((item, index) => (
              (item.icon ? (
                <div key={index}>
                  <PrismicLink field={item.link} title={item.label} target="_blank">
                    <FontAwesomeIcon
                      icon={['fab', item.icon]}
                      className="w-6 h-6 text-gray-500 dark:text-white hover:text-orange dark:hover:text-orange transition duration-500"
                    />
                  </PrismicLink>
                </div>
              ) : '')
            ))}
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
