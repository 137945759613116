import React from "react";
import { Footer, Header } from "./index";

const Layout = ({ headerLinks, page, footer, children }) => (
  <>
    <Header headerLinks={headerLinks} overlayHeader={page?.type === 'homepage'} />
    <main>
      {children}
    </main>
    <Footer footer={footer} />
  </>
);

export default Layout;
